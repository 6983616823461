import React from 'react';
import Layout from '../../components/Layout';
import SEO from '../../components/Seo';
import Post from "../components/post"
import { graphql } from 'gatsby';

const PostTemplate = ({data}) => {
    const post = data.blogPost;
    const image = '/media/'.concat(post.slug.split('/')[2], '.png');

	return (
		<Layout customSEO>
			<SEO
				title={post.title}
                description={post.excerpt}
                image={image}/>
			<Post data={data}/>
		</Layout>
	)
};

export const query = graphql`
  query PostTemplateQuery(
    $id: String!
    $previousId: String
    $nextId: String
    $maxWidth: Int
  ) {
    site {
      siteMetadata {
        title
        social {
          name
          url
        }
      }
    }
    blogPost(id: { eq: $id }) {
      id
      excerpt
      body
      slug
      title
      tags
      date(formatString: "MMMM DD, YYYY")
      image {
        childImageSharp {
          fluid(maxWidth: $maxWidth) {
            ...GatsbyImageSharpFluid
            src
          }
        }
      }
      imageAlt
      socialImage {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
            src
          }
        }
      }
    }
    previous: blogPost(id: { eq: $previousId }) {
      id
      excerpt
      slug
      title
      date(formatString: "MMMM DD, YYYY")
    }
    next: blogPost(id: { eq: $nextId }) {
      id
      excerpt
      slug
      title
      date(formatString: "MMMM DD, YYYY")
    }
  }
`

export default PostTemplate;