import React, {Fragment} from 'react';
import styles from './Footer.module.scss';
import CookieConsentWrapper from '../CookieConsentWrapper';
import MailChimpWrapper from '../MailChimpWrapper';

const Footer = () => {

  return (
    <Fragment>
      <div className={`footer level ${styles['footer__newsletter']}`} id='footer'>
        <div className={`${styles['footer__newsletter-level']}`}>
          <div className={`subtitle is-5 ${styles['footer__newsletter-text']}`}>
            Get a weekly digest of tech articles, every Thursday.
          </div>
          <ul className={`subtitle is-5 ${styles['footer__newsletter-reasons']}`}>
            <div>✔ 5+ articles from French tech companies</div>
            <div>✔ 1 new blog announced every month</div>
            <div>✔ Updates about growing Guriosity</div>
          </ul>
          <MailChimpWrapper/>
          <div className={`${styles['footer__newsletter-pitch']}`}>
            From <a href='/about/'>Ari</a>, to 700+ developers. No spam.
          </div>
        </div>
      </div>
      <div className={`${styles['footer__contact']}`}>
        <p className={`${styles['footer__contact-links']}`}>
          <a href="mailto:ari@guriosity.com">
            ari@guriosity.com
          </a> | <a href='/about/'>
            About
          </a> | <a href='/privacy/'>
            Privacy
          </a></p>
      </div>
      <CookieConsentWrapper/>
    </Fragment>
  )
}

export default Footer;