import React from 'react';
import { Link } from 'gatsby'
import styles from './post-author.module.scss'

const Author = () => (
    <section className={`${styles['post-author']}`}>
        <div className={`${styles['post-author__label']}`}>
            WRITTEN BY
        </div>
        <p className={`${styles['post-author__name']}`}>
            <Link to='/about/'>Ari Bajo</Link>
        </p>
        <p className={`${styles['post-author__description']}`}>
            Freelance Data Engineer and Technical Writer. I digest French engineering blogs.
        </p>
    </section>
)

export default Author