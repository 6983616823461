import React, { Fragment } from "react"
import { Link } from "gatsby"

import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"

import PostTitle from "gatsby-theme-blog-core/src/components/post-title"

import MailChimpWrapper from '../../components/MailChimpWrapper'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Author from './post-author'
import styles from './post.module.scss'

const shortcodes = { MailChimpWrapper }

const Post = ({ data }) => {
  const post = data.blogPost;
  
  return (
    <Fragment>
      <Header/>
      <main className={`${styles['post__container']}`}>
        <article className={`${styles['post__article']}`}>
          <header className={`${styles['post__header']}`}>
            <PostTitle>{post.title}</PostTitle>
            <div className={`${styles['post__details']}`}>
              <Link to='/about/'>Ari Bajo</Link> - <span>{post.date}</span>
            </div>
          </header>
          <section className={`${styles['post__body']}`}>
            <MDXProvider components={shortcodes}>
              <MDXRenderer>{post.body}</MDXRenderer>
            </MDXProvider>
          </section>
          <hr/>
          <Author/>
        </article>
      </main>
      <Footer/>
    </Fragment>
  )
}

export default Post
